import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Főoldal | Justitia jogi tanácsadók
			</title>
			<meta name={"description"} content={"Justitia Justitia ügyvédi iroda: Az igazságszolgáltatás erősítése, az Önért való kiállás"} />
			<meta property={"og:title"} content={"Főoldal | Justitia jogi tanácsadók"} />
			<meta property={"og:description"} content={"Justitia Justitia ügyvédi iroda: Az igazságszolgáltatás erősítése, az Önért való kiállás"} />
			<meta property={"og:image"} content={"https://konasbaltined.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://konasbaltined.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://konasbaltined.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://konasbaltined.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://konasbaltined.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://konasbaltined.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://konasbaltined.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
				sm-align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
				>
					Üdvözöljük a Justitia Jogi Tanácsadóknál
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="normal 400 28px/1.5 --fontFamily-sansHelvetica"
					color="#50555a"
					padding="0px 0px 0px 0px"
					text-align="center"
					lg-padding="0px 0 0px 0"
					md-font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				>
					A Justitia Jogi Tanácsadóknál elkötelezettek vagyunk amellett, hogy kivételes jogi szolgáltatásokat nyújtsunk személyes érintéssel. Tapasztalt ügyvédekből álló csapatunk számos jogi területre szakosodott, így biztosítva átfogó támogatást az Ön jogi igényeihez. A hagyományos jogi bölcsességet modern megközelítésekkel ötvözzük, hogy innovatív megoldásokat kínáljunk.
				</Text>
				<Button
					margin="0px 0 20px 0px"
					padding="14px 35px 14px 35px"
					background="#3f24d8"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="#3f24d8"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					sm-width="100%"
					type="link"
					text-decoration-line="initial"
					href="/contacts"
				>
					Kapcsolatfelvétel
				</Button>
			</Box>
			<Image
				src="https://konasbaltined.com/img/1.jpg"
				display="block"
				height="600px"
				object-fit="cover"
				border-radius="35px"
				md-height="400px"
				sm-height="300px"
			/>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-4">
			<Text
				margin="0px 0px 90px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				Miért válassza a Justitia Jogi Tanácsadókat?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Szakértelem különböző jogi területeken
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Akár vállalati jogról, akár családi ügyekről, akár polgári peres ügyekről van szó, sokszínű csapatunk fedezi Önt.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Ügyfélközpontú megközelítés
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Személyre szabott tanácsokat és stratégiákat kínálunk az Ön egyedi jogi helyzetéhez igazodva.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Átlátható kommunikáció
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							A jogi szakzsargon zavaró lehet. Mi minden lépésnél biztosítjuk a világos, egyértelmű kommunikációt.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://konasbaltined.com/img/2.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://konasbaltined.com/img/3.jpg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					Elkötelezettség a kiválóság iránt: Sikeres ügyek és elégedett ügyfelek eredményei önmagukért beszélnek.
				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://konasbaltined.com/img/4.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Etikus és bizalmas szolgáltatás: A legmagasabb etikai és titoktartási normákat tartjuk be minden ügyfelünkkel való kapcsolatunkban.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});